<template>
  <div class="coupon" :class="{ noneCoupon }">
    <header ref="header" v-if="selectable">选择优惠券</header>
    <header ref="header" v-else>我的券包</header>
    <template v-if="!loading">
      <div class="list">
        <div
          class="list-item"
          :key="item.id"
          v-for="(item, index) in couponAvailableList.filter(
            (item) => item.type === 0 || item.type === 1
          )"
        >
          <div class="partTop">
            <div class="coupon-name">
              <div>{{ item.name }}</div>
              <div>{{ item.description }}</div>
            </div>
            <div class="coupon-limit">
              <div class="coupon-amount">
                <div class="notDiscount" v-if="item.type === 0">
                  <span>¥</span>
                  <span>{{ item.discountPrice.toFixed(1).split('.')[0] }}</span>
                  <span
                    >.{{ item.discountPrice.toFixed(1).split('.')[1] }}</span
                  >
                </div>
                <div class="discount" v-else>
                  {{ item.discountPrice * 10 }}
                  <span>折</span>
                </div>
                <div class="description" v-if="item.overPrice > 0">
                  满
                  <span>{{ item.overPrice }}</span>
                  可用
                </div>
                <div class="description" v-else>无门槛</div>
              </div>
              <div
                class="coupon-status"
                v-if="selectable"
                @click="selectCoupon(item, index)"
              >
                <span :class="{ selected: item.select }"></span>
              </div>
            </div>
          </div>
          <div class="partBottom">
            <div class="expire">
              <span>有效期：</span>
              <span>{{ item.beginDate }}～{{ item.endDate }}</span>
            </div>
            <div class="peroid" v-if="item.timePeriod">
              <span>使用时段：</span>
              <span>{{ item.timePeriod }}</span>
            </div>
            <div
              class="remark"
              v-if="item.remark"
              @click="openCoupon(1, item.id)"
            >
              <div>
                <span>使用说明</span>
                <img
                  class="toggle"
                  v-if="item.open"
                  src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/put.png"
                />
                <img
                  class="toggle"
                  v-else
                  src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/pull.png"
                />
              </div>
              <div class="remark-detail">
                <span v-if="item.open">{{ item.remark }}</span>
              </div>
            </div>
          </div>
          <div class="overdue" v-if="item.overdue === 0">今日到期</div>
        </div>
      </div>
      <template v-if="selectable">
        <div class="list unAvailable">
          <div
            class="list-item"
            :key="item.id"
            v-for="item in couponNotAvailableList.filter(
              (item) => item.type === 0 || item.type === 1
            )"
          >
            <div class="partTop">
              <div class="coupon-name">
                <div>{{ item.name }}</div>
                <div>{{ item.description }}</div>
              </div>
              <div class="coupon-limit">
                <div class="coupon-amount">
                  <div class="notDiscount" v-if="item.type === 0">
                    <span>¥</span>
                    <span>{{
                      item.discountPrice.toFixed(1).split('.')[0]
                    }}</span>
                    <span
                      >.{{ item.discountPrice.toFixed(1).split('.')[1] }}</span
                    >
                  </div>
                  <div class="discount" v-else>
                    {{ item.discountPrice * 10 }}
                    <span>折</span>
                  </div>
                  <div class="description" v-if="item.overPrice > 0">
                    满
                    <span>{{ item.overPrice }}</span>
                    可用
                  </div>
                  <div class="description" v-else>无门槛</div>
                </div>
                <div class="coupon-status">
                  <span class="disabled"></span>
                </div>
              </div>
            </div>
            <div class="partBottom">
              <div class="expire">
                <span>有效期：</span>
                <span>{{ item.beginDate }}～{{ item.endDate }}</span>
              </div>
              <div class="peroid" v-if="item.timePeriod">
                <span>使用时段：</span>
                <span>{{ item.timePeriod }}</span>
              </div>
              <div
                class="remark"
                v-if="item.remark"
                @click="openCoupon(2, item.id)"
              >
                <div>
                  <span>使用说明</span>
                  <img
                    class="toggle"
                    v-if="item.open"
                    src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/put.png"
                  />
                  <img
                    class="toggle"
                    v-else
                    src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/pull.png"
                  />
                </div>
                <div class="remark-detail">
                  <span v-if="item.open">{{ item.remark }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="overdue" v-if="item.overdue === 0">今日到期</div> -->
            <div class="reason">
              <img src="../assets/imgs/warning2-dark.png" />
              <div>不可用原因：</div>
              <div>{{ item.disablePrompt }}</div>
            </div>
          </div>
        </div>
      </template>
      <div class="noneCoupon" v-if="noneCoupon">
        <img src="../assets/imgs/noneCoupon.png" alt="" />
        <span>暂无优惠券</span>
      </div>
      <div class="invalid" v-if="!selectable" @click="toInvalid">
        查看无效券
      </div>
      <div class="invalidSpace" v-if="!selectable"></div>
      <div class="footer" v-if="selectable && couponAvailableList.length">
        <div class="footer-content">
          <div v-if="couponInfo">已选择<span>1</span>张券</div>
          <div v-else>未选择优惠券</div>
          <div @click="handleOk">确定</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getUserCouponList } from '@/api/api';

export default {
  mounted() {
    const query = this.$router.history.current.query;
    this.info = query;
    console.log(query, '...query');
    this.noCheck = query.noCheck == 'true' ? true : false;
    sessionStorage.setItem('couponInfo', JSON.stringify(query));
    this.getCouponList();
    this.listener = this.scrollTitle();
    this.utils.setTitle('');
  },
  listener: null,
  data() {
    return {
      loading: false, // 页面是否在加载
      title: '',
      info: null, //订单确认页传过来的数据，其他途径进来没有
      couponAvailableList: [],
      couponNotAvailableList: [],
      couponInfo: null,
      originalPrice: 0,
      noCheck: false,
    };
  },
  computed: {
    selectable() {
      // 是否可以选择优惠券
      return this.info?.orderCode;
    },
    noneCoupon() {
      return (
        (this.selectable &&
          this.couponAvailableList.length === 0 &&
          this.couponNotAvailableList.length === 0) ||
        (!this.selectable && this.couponAvailableList.length === 0)
      );
    },
  },
  watch: {
    title(val) {
      this.utils.setTitle(val);
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.listener);
  },
  methods: {
    selectCoupon(info, index) {
      //没有选择优惠券
      if (info.select) {
        this.couponInfo = null;
        this.$set(
          this.couponAvailableList,
          index,
          Object.assign(this.couponAvailableList[index], { select: false })
        );
        this.couponInfo = this.originalPrice; //这里设置是为了告诉订单确认页没有选择优惠券，用的就是默认的优惠券
      }
      //选择了非推荐的优惠券
      else {
        this.couponAvailableList = this.couponAvailableList.map((d, i) =>
          Object.assign(d, { select: i === index })
        );
        this.couponInfo = info;
      }
    },
    toInvalid() {
      this.$router.push('/invalidCoupon');
    },
    openCoupon(type, id) {
      // type 1可以 2不可用
      let list =
        type == 1 ? this.couponAvailableList : this.couponNotAvailableList;
      const newList = list.map((d) => {
        if (d.id === id) {
          d.open = !d.open;
        }
        return d;
      });
      type == 1
        ? (this.couponAvailableList = newList)
        : (this.couponNotAvailableList = newList);
    },
    getCouponList() {
      this.loading = true;
      getUserCouponList({
        orderCode: this.info.orderCode ?? '',
        skuCodeSkuCount: this.info.skuListStr ?? '',
      })
        .then((data) => {
          if (data) {
            this.couponAvailableList = data.couponAvailableList.map((d) => {
              if (this.noCheck) {
                //  如果订单确认页没有选择优惠券 则 优惠券也不展示选中态
                d.select = false;
              }
              if (d.select) {
                this.couponInfo = d;
              }
              return Object.assign(d, {
                open: false,
                select: d.select ?? false,
              });
            });
            this.couponNotAvailableList = data.couponNotAvailableList.map((d) =>
              Object.assign(d, { open: false, select: false })
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleOk() {
      sessionStorage.setItem(
        'couponInfo',
        JSON.stringify(
          Object.assign({}, this.info, { id: '' }, this.couponInfo)
        )
      );
      this.$router.back();
    },
    scrollTitle() {
      const scrollElement = document.querySelector('#app');
      const { height } = this.$refs.header.getBoundingClientRect();
      const callback = () => {
        if (scrollElement.scrollTop < height) {
          this.title = '';
        } else {
          this.title = this.selectable ? '选择优惠券' : '我的券包';
        }
      };
      scrollElement.addEventListener('scroll', callback, {
        passive: true,
      });
      return callback;
    },
  },
};
</script>

<style scoped lang="scss">
[data-theme='light'] {
  .invalid {
    &:active {
      background: #c2c2c2;
    }
  }
}
.coupon {
  min-height: 100%;
  background: #f5f5fa;
  @include background_color('coupon_bg');
  &.noneCoupon {
    height: 100vh;
    background: white;
    @include background_color('coupon_bg');
  }
  header {
    height: 1.16rem;
    font-size: 0.48rem;
    font-weight: bold;
    color: #181818;
    line-height: 1.16rem;
    padding-left: 0.48rem;
    background: white;
    top: 0;
    left: 0;
    @include font_color('title_color');
    @include background_color('title_bg');
  }
  .noneCoupon {
    margin: 1.6rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 4.5rem;
      height: 4.5rem;
    }
    span {
      margin-top: 0.3rem;
      font-size: 0.36rem;
      color: #222222;
      @include font_color('title_color');
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;
    }
  }
  .list {
    &-item {
      width: 6.9rem;
      min-height: 2.76rem;
      background: white;
      @include background_color('card_bg');
      position: relative;
      margin: 0.3rem auto 0;
      padding: 0.3rem 0.32rem 0.3rem 0.4rem;
      box-sizing: border-box;
      border-radius: 0.16rem;
      &::after {
        width: 0.28rem;
        height: 0.28rem;
        display: block;
        content: '';
        position: absolute;
        right: -0.14rem;
        top: 1.36rem;
        background: #f5f5f5;
        z-index: 2;
        border-radius: 50%;
        @include background_color('coupon_bg');
      }
      &::before {
        width: 0.28rem;
        height: 0.28rem;
        display: block;
        content: '';
        position: absolute;
        left: -0.14rem;
        top: 1.36rem;
        background: #f5f5f5;
        z-index: 2;
        border-radius: 50%;
        @include background_color('coupon_bg');
      }
      .partTop {
        border-bottom: 0.02rem dashed #eaeaea;
        @include border_color('coupon_card_border');
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.2rem;
        .coupon-name {
          div {
            &:nth-child(1) {
              font-size: 0.32rem;
              color: #222222;
              font-weight: bold;
              margin-bottom: 0.12rem;
              @include font_color('title_color');
            }
            &:nth-child(2) {
              font-size: 0.24rem;
              color: #666666;
              @include font_color('coupon_card_text');
            }
          }
        }
        .coupon-limit {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .coupon-amount {
            text-align: right;
            color: #ff4a26;
            @include font_color('coupon_amount_color');
            .notDiscount {
              font-weight: bold;
              span {
                &:nth-child(1) {
                  font-size: 0.28rem;
                }
                &:nth-child(2) {
                  font-size: 0.6rem;
                }
                &:nth-child(3) {
                  font-size: 0.28rem;
                }
              }
            }
            .discount {
              font-weight: bold;
              font-size: 0.6rem;
              span {
                font-size: 0.28rem;
              }
            }
            .description {
              margin-top: 0.04rem;
              font-size: 0.24rem;
              span {
                font-weight: bold;
              }
            }
          }
          .coupon-status {
            -webkit-tap-highlight-color: transparent;
            width: 0.6rem;
            height: 0.8rem;
            margin-top: 0.2rem;
            // margin-left: 0.16rem;
            align-self: flex-start;
            span {
              float: right;
              display: block;
              width: 0.32rem;
              height: 0.32rem;
              background-image: url('../assets/imgs/coupon-unselected.png');
              @include bg_image('coupon_card_item_unselected');
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              &.selected {
                background-image: url('../assets/imgs/coupon-selected.png');
                @include bg_image('coupon_card_item_selected');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              }
              &.disabled {
                background-image: url('../assets/imgs/coupon-disabled.png');
                @include bg_image('coupon_card_item_disabled');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
      .partBottom {
        font-size: 0.24rem;
        padding: 0.3rem 0 0;
        > div {
          color: #999999;
          @include font_color('coupon_card_text');
          line-height: 0.36rem;
        }
        .remark {
          img {
            margin-left: 0.12rem;
            vertical-align: middle;
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }
      .overdue {
        width: 1.16rem;
        height: 0.34rem;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 0.34rem;
        font-size: 0.22rem;
        color: #ff4a26;
        @include font_color('coupon_amount_color');
        text-align: center;
        background: rgba(255, 74, 38, 0.16);
        border-radius: 0 0.16rem 0 0.16rem;
      }
    }
    &.unAvailable {
      .list-item {
        padding-bottom: 1rem;
        position: relative;
        .partTop {
          .coupon-name {
            div {
              &:nth-child(1) {
                color: #ababab;
                @include font_color('coupon_card_text');
              }
              &:nth-child(2) {
                color: #ababab;
                @include font_color('coupon_card_text');
              }
            }
          }
          .coupon-limit {
            .coupon-amount {
              color: #ababab;
              @include font_color('coupon_card_text');
            }
          }
        }
        .overdue {
          width: 1.16rem;
          height: 0.34rem;
          position: absolute;
          top: 0;
          right: 0;
          line-height: 0.34rem;
          font-size: 0.22rem;
          color: #ff4a26;
          text-align: center;
          background: rgba(255, 74, 38, 0.16);
          @include background_color('coupon_overdue_bg');
          border-radius: 0 0.16rem 0 0.16rem;
        }
        .reason {
          display: flex;
          align-items: center;
          width: 6.9rem;
          min-height: 0.72rem;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #fffbeb;
          @include background_color('coupon_unavailable_bg');
          @include font_color('coupon_card_text');
          border-radius: 0 0 0.16 0.16;
          font-size: 0.22rem;
          img {
            width: 0.24rem;
            height: 0.24rem;
            margin: 0 0.1rem 0 0.36rem;
          }
          div {
            &:nth-child(2) {
              color: #ff4a26;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 1.28rem;
    margin-top: 0.3rem;
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 1.28rem;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: white;
      @include background_color('card_bg');
      div {
        &:nth-child(1) {
          font-weight: bold;
          margin-left: 0.4rem;
          font-size: 0.36rem;
          color: #222222;
          @include font_color('title_color');
          span {
            color: #ff613e;
            display: inline-block;
            padding: 0 0.08rem;
          }
        }
        &:nth-child(2) {
          margin-right: 0.4rem;
          width: 2.56rem;
          height: 0.88rem;
          background: #ff613e;
          line-height: 0.88rem;
          color: white;
          font-size: 0.4rem;
          text-align: center;
          border-radius: 0.47rem;
        }
      }
    }
  }
}
.invalid {
  width: 2.08rem;
  height: 0.64rem;
  line-height: 0.64rem;
  font-size: 0.28rem;
  color: #222;
  text-align: center;
  margin: 0.48rem auto 0;
  border-radius: 0.32rem;
  border: 0.02px solid #e5e5e5;
  @include border_color('coupon_invalid_enter_border');
  @include font_color('title_color');
}
.invalidSpace {
  height: 0.62rem;
}
</style>
